import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <section className="text-center">
        <h1 className="text-6xl">😔 Oops.. we couldn't find that</h1>
      </section>
    </Layout>
  )
}

export default NotFoundPage
